const qrCodeEl = document.getElementById('my-qr-code');
let code = null;
let locale = null;
let interval = null;

function stopInterval() {
    clearInterval(interval);
    interval = null;
}

async function checkForReport() {
    try {
        const response = await window.axios.post('report/qr', {
            code: code
        });
        if (response.data.hash) {
            stopInterval();
            location.href = "report/" + response.data.hash + "/" + locale;
        }
        qrCodeEl.innerHTML = 'Time left: ' + (60 - response.data.seconds);
    } catch (ex) {
        // TODO: display error to user.
        qrCodeEl.innerHTML = ex.toString();
        qrCodeEl.classList.add('text-danger');
        stopInterval();
    }

}

if (qrCodeEl) {
    code = qrCodeEl.dataset.code;
    locale = qrCodeEl.dataset.locale;
    interval = setInterval(checkForReport, 2000);
}
