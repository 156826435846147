import { right } from '@popperjs/core';
import * as echarts from 'echarts';


const masChartEl = document.getElementById('mas-chart');

if (masChartEl) {
    init();
}

function init() {

    const masChartMedsEl = document.getElementById('mas-chart-meds');
    const mctChartEl = document.getElementById('mct-chart');
    const mcQolChartEl = document.getElementById('mc-qol-chart');
    const mctAndMcQolDataEl = document.getElementById('mct-mc-qol-data');
    const masTranslation = JSON.parse(masChartEl.dataset.translation);

    const masRows = JSON.parse(masChartEl.dataset.masRows);
    const masMedicinesRows = JSON.parse(masChartEl.dataset.masMedicinesRows);
    //const masMedicines = JSON.parse(masChartEl.dataset.masMedicines);
    const masDates = JSON.parse(masChartEl.dataset.masDates);
    const masMedIdMapper = JSON.parse(masChartEl.dataset.masMedIdMapper);
    const mctAndMcQolDates = JSON.parse(mctAndMcQolDataEl.dataset.dates);
    const mctAndMcQolRows = JSON.parse(mctAndMcQolDataEl.dataset.rows);


    const masDatesIndex = {}; // Date => index
    const mctAndMcQolDatesIndex = {}; // Date => index

    const mctData = [];
    const mcQolData = [];

    const masDatesDone = {}; // [date]
    const mctDatesDone = {}; // [date]
    const mcQolDatesDone = {}; // [date]
    const masMedicineDatesDone = {}; // [date][patient_questionnaire.id]

    const masMedicineData = [];
    const masData = [];
    masDates.forEach(function (date, index) {
        masDatesIndex[date] = index;
        masData.push(null);
        masMedicineData.push([]);
    });

    mctAndMcQolDates.forEach(function (date, index) {
        mctAndMcQolDatesIndex[date] = index;
        mctData.push(null);
        mcQolData.push(null);
    });

    masRows.forEach(function (masRow) {
        if (masRow.completed_at in masDatesDone) {
            return;
        }
        const index = masDatesIndex[masRow.completed_at];
        masData[index] = masRow.score_total;
        masDatesDone[masRow.completed_at] = true;
    });

    mctAndMcQolRows.forEach(function (row) {
        if (row.prom == 'mct') {
            if (row.completed_at in mctDatesDone) {
                return;
            }
            const index = mctAndMcQolDatesIndex[row.completed_at];
            mctData[index] = row.score_total;
            mctDatesDone[row.completed_at] = true;
        } else if (row.prom === 'mc_qol') {
            if (row.completed_at in mcQolDatesDone) {
                return;
            }
            const index = mctAndMcQolDatesIndex[row.completed_at];
            mcQolData[index] = row.score_total;
            mcQolDatesDone[row.completed_at] = true;
        }
    });

    masMedicinesRows.forEach(function (masMedicineRow) {
        if (!(masMedicineRow.completed_at in masMedicineDatesDone)) {
            masMedicineDatesDone[masMedicineRow.completed_at] = masMedicineRow.patient_questionnaire_id;
        }
        if (masMedicineDatesDone[masMedicineRow.completed_at] != masMedicineRow.patient_questionnaire_id) {
            return;
        }
        const index = masDatesIndex[masMedicineRow.completed_at];
        masMedicineData[index].push(masMedicineRow.medicine_id);
    });

    console.log(masDates);

    // Add table to chart MAS for meds
    const medsTableRowDay = [];
    const medsTableRowMonth = [];
    const medsTableRowMeds = [];
    masDates.forEach(function (date, index) {
        const parts = date.split('-');
        medsTableRowDay.push('<td>' + parts[2] + '</td>');
        medsTableRowMonth.push('<td>' + parts[1] + '</td>');
        const meds = [];
        for (let i = 0; i < Object.keys(masMedIdMapper).length; i++) {
            meds.push('&nbsp;');
        }
        masMedicineData[index].forEach(function(id) {
            const index = masMedIdMapper[id];
            meds[index - 1] = index;
        });
        medsTableRowMeds.push('<td>' + meds.join("<br>") + '</td>');

    });
    masChartMedsEl.innerHTML = '<table class="my-pre-mas-table table table-bordered table-sm"><tr><td>' + masTranslation.month + '</td></tr><tr><td>' + masTranslation.day + '</td></tr><tr><td>' + masTranslation.medication + ' *</td></tr></table><table class="my-mas-table table table-bordered table-sm"><tr>' + medsTableRowMonth.join("\n") + '</tr><tr>' + medsTableRowDay.join("\n") + '</tr><tr>' + medsTableRowMeds.join("\n") + '</tr></table>';

    // MCT
    const mctChart = echarts.init(mctChartEl);
    const mctOptions = {
        grid: {
            show: true,
            top: 10,
            right: 0,
            left: 100,
            bottom: 30
        },
        xAxis: {
            type: 'category',
            data: mctAndMcQolDates,
            splitLine: {
                show: true,
                interval: 'auto'
            },
            axisLabel: {
                show: true,
                // formatter: function(value, index) {
                //     if (mctData[index] !== null) {
                //         console.log(index, value);
                //         return 'value';
                //     }
                //     return '';
                // }
            },
            axisTick: {
                length: 10,
                interval: 'auto',
                lineStyle: {
                    color: '#d0d0d0'
                }
            },
            axisLine: {
                show: false
            }
        },
        yAxis: {
            type: 'value',
            min: 0,
            max: 20,
            splitLine: {
                show: true,
                interval: 0
            }
        },
        series: [
            {
                type: 'line',
                //symbolSize: 4,
                //symbol: 'circle',
                showAllSymbol: true,
                connectNulls: true,
                data: mctData
            }
        ]
    };
    mctChart.setOption(mctOptions);

    const mcQolChart = echarts.init(mcQolChartEl);
    const mcQolOptions = {
        grid: {
            show: true,
            top: 10,
            right: 0,
            left: 100,
            bottom: 30
        },
        xAxis: {
            type: 'category',
            data: mctAndMcQolDates,
            splitLine: {
                show: true,
                interval: 'auto'
            },
            axisLabel: {
                show: true
            },
            axisTick: {
                length: 10,
                interval: 'auto',
                lineStyle: {
                    color: '#d0d0d0'
                }
            },
            axisLine: {
                show: false
            }
        },
        yAxis: {
            type: 'value',
            min: 0,
            max: 108,
            splitLine: {
                show: true,
                interval: 0
            }
        },
        series: [
            {
                type: 'line',
                showAllSymbol: true,
                connectNulls: true,
                data: mcQolData
            }
        ]
    };
    mcQolChart.setOption(mcQolOptions);

    const masChart = echarts.init(masChartEl);
    const masOption = {
        grid: {
            show: true,
            top: 10,
            right: 0,
            left: 100,
            bottom: 10
        },
        xAxis: {
            type: 'category',
            data: masDates,
            splitLine: {
                show: true,
                interval: 0
            },
            axisLabel: {
                show: false
            },
            axisTick: {
                length: 10,
                interval: 0,
                lineStyle: {
                    color: '#d0d0d0'
                }
            },
            axisLine: {
                show: false
            }
        },
        yAxis: {
            type: 'value',
            min: 0,
            max: 36,
            splitLine: {
                show: true,
                interval: 0
            }
        },
        series: [
            {
                type: 'line',
                data: masData
            }
        ]
    };
    masChart.setOption(masOption);

}


